import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ValuesSection,
  ValueItemDefault,
  ValueItemInput,
  InputContainer,
} from './valueSelector.styles';

const MAXIMUM_INPUT_LENGTH = 6;

export const ValueSelector = ({
  values,
  theme,
  isMonthly,
  activeValue,
  customValue,
  handleValueChange,
  setCustomValue,
}) => {
  const customInputRef = useRef(null);
  const themeBase = theme.split('_ACCESSIBLE')[0].toLowerCase();

  const isActiveValue = (id) => {
    return isMonthly ? id === activeValue.monthly : id === activeValue.oneTime;
  };

  const getValue = (id) => {
    return isMonthly ? values.monthlyValues[id] : values.oneTimeValues[id];
  };

  const handleCustomChange = (e) => {
    setCustomValue(e.target.value.slice(0, MAXIMUM_INPUT_LENGTH));
    if (e.target.value.toString().length > MAXIMUM_INPUT_LENGTH) {
      customInputRef.current.value = customValue;
    }
  };

  // Create Button accessiblity for ValueItems
  // Converting them directly to Buttons breaks flex on mobile iOS
  const onKeyDown = (event, i) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleValueChange(i);
    }
  };

  const generateDefaultValueItems = () => {
    return [0, 1, 2].map((i) =>
      <ValueItemDefault
        active={isActiveValue(i)}
        onClick={() => handleValueChange(i)}
        inputActive={isActiveValue(3)}
        isMonthly={isMonthly}
        key={i}
        role="button"
        tabIndex="0"
        onKeyDown={(e) => onKeyDown(e, i)}
      >
        <sup key={`dollar-sign-${i}`}>$</sup>
        {getValue(i)}
        {isMonthly && <span key={`per-month-${i}`}>/mo</span>}
      </ValueItemDefault>
    );
  };




  const handleInputFocus = () => {
    handleValueChange(3);
  };

  useEffect(() => {
    if (customInputRef.current) {
      if (isActiveValue(3)) {
        customInputRef.current.focus();
        customInputRef.current.classList.add('active');
      } else if (!(customInputRef.current.classList.length === 0)) {
        customInputRef.current.classList.remove('active');
      }
    }
  }, [activeValue, isMonthly]);

  return (
    <ValuesSection theme={theme} themeBase={themeBase}>
      {generateDefaultValueItems()}
      <ValueItemInput
        active={isActiveValue(3)}
        length={customValue.toString().length}
        onClick={() => handleInputFocus()}
        isMonthly={isMonthly}
        role="button"
        tabIndex="0"
        onKeyDown={(e) => onKeyDown(e, 3)}
      >
        <span>OTHER</span>
        <InputContainer>
          <sup>$</sup><input type="number" ref={customInputRef} onChange={e => handleCustomChange(e)}></input>
          {isMonthly && <sub>/mo</sub>}
        </InputContainer>
      </ValueItemInput>
    </ValuesSection>
  );
};

ValueSelector.propTypes = {
  values: PropTypes.shape({
    monthlyValues: PropTypes.arrayOf(PropTypes.number).isRequired,
    oneTimeValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  theme: PropTypes.string,
  isMonthly: PropTypes.bool,
  activeValue: PropTypes.object.isRequired,
  customValue: PropTypes.string.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  setCustomValue: PropTypes.func.isRequired,
};

ValueSelector.defaultProps = {
  theme: 'BLUE',
  isMonthly: true,
};

export default ValueSelector;
