import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BUTTON_COLOR_DONATION,
  TICK_BY_COLOR_THEME,
  BoxContainer,
  DonationContainer,
  TabContainer,
  GiveOnceTab,
  MonthlyTab,
  TabsDescription,
  CheckboxContainer,
  Label,
  Checkbox,
  Button,
} from './donationBox.styles';
import { COLORS, TEXT_COLOR } from '../styles/homePageStyles';
import { PlatformDetector } from '../../platformDetector/platformDetector.component';
import LockIconSvgWhite from '../../../images/icon-lock-plain.svg';
import LockIconSvgDarkGrey from '../../../images/icon-lock-plain-dark-grey.svg';
import TickIconDarkGrey from '../../../images/icon-tick-dark-grey.svg';
import { ValueSelector } from './valueSelector/valueSelector.component';
import { appendHomePageParams } from '../utils/linkHelpers';
import { Markdown } from '../markdown';

export const TYPES = {
  CHECK: 'check',
  TABS: 'tabs',
};

export const DEFAULT_MONTHLY_BUTTON_TEXT = 'Donate Monthly';
export const DEFAULT_ONE_TIME_BUTTON_TEXT = 'Give Once';

export const DonationBox = ({
  description,
  values,
  theme,
  type,
  preselectedMonthly,
  preselectedOneTime,
  buttonTextMonthly,
  buttonTextOneTime,
  isMonthlyDefault,
  handleActiveValue,
  handleFrequencyChange,
}) => {
  const themeBase = theme.split('_ACCESSIBLE')[0].toLowerCase();

  const [isMonthlyDonation, setIsMonthlyDonation] = useState(isMonthlyDefault);
  const [activeValue, setActiveValue] = useState({
    monthly: preselectedMonthly,
    oneTime: preselectedOneTime,
  });
  const [customValue, setCustomValue] = useState('');

  const toggleFrequency = () => {
    handleFrequencyChange(!isMonthlyDonation);
    setIsMonthlyDonation(!isMonthlyDonation);
  };

  const handleValueChange = i => {
    if (isMonthlyDonation) {
      setActiveValue({ ...activeValue, monthly: i });
    } else {
      setActiveValue({ ...activeValue, oneTime: i });
    }
  };

  // Do not allow Custom Value of null or 0 to exist
  // Revert to current 'preselected' value
  const getSanitizedCustomValue = () => {
    if (customValue > 0) {
      return customValue;
    }
    if (isMonthlyDonation) {
      return values.monthlyValues[preselectedMonthly];
    }
    return values.oneTimeValues[preselectedOneTime];
  };

  const getActiveValue = () => {
    if (
      (isMonthlyDonation && activeValue.monthly === 3) ||
      (!isMonthlyDonation && activeValue.oneTime === 3)
    ) {
      return getSanitizedCustomValue();
    } else if (isMonthlyDonation) {
      return values.monthlyValues[activeValue.monthly];
    }
    return values.oneTimeValues[activeValue.oneTime];
  };

  const buttonUrl = () => {
    let url = 'https://donate.thehumaneleague.org/';
    // Hardcoding URL for EOY 2024 Campaign for Fundraising Hero
    // To be removed and replaced with Contentful field
    if (type === TYPES.TABS) {
      url +=
        'animals-deserve-better?utm_campaign=2024+q4+digital+campaign&utm_content=fundraising+hero&sourceid=1066738';
    } else {
      url += 'donate';
    }

    const value = getActiveValue();
    const donateParams = {
      am: value,
      r: isMonthlyDonation,
    };
    return appendHomePageParams(url, donateParams);
  };

  const getTickImage = () => {
    return (
      <img
        src={TICK_BY_COLOR_THEME[themeBase] ? TICK_BY_COLOR_THEME[themeBase] : TickIconDarkGrey}
        alt="Tick Icon"
      />
    );
  };

  useEffect(() => {
    handleActiveValue(getActiveValue());
  }, [activeValue, customValue, isMonthlyDonation]);

  return (
    <PlatformDetector>
      {({ isMobile }) => (
        <BoxContainer theme={theme} themeBase={themeBase} type={type}>
          {type === TYPES.TABS && (
            <TabContainer>
              <GiveOnceTab
                active={!isMonthlyDonation}
                onClick={() => isMonthlyDonation ? toggleFrequency() : null}
              >
                {!isMonthlyDonation && getTickImage()}GIVE ONCE
              </GiveOnceTab>
              <MonthlyTab
                active={isMonthlyDonation}
                onClick={() => !isMonthlyDonation ? toggleFrequency() : null}
              >
                {isMonthlyDonation && getTickImage()}MONTHLY
              </MonthlyTab>
            </TabContainer>
          )}
          <DonationContainer type={type}>
            {type === TYPES.CHECK && !!description && <div>{description}</div>}
            <ValueSelector
              values={values}
              theme={theme}
              isMonthly={isMonthlyDonation}
              activeValue={activeValue}
              customValue={customValue}
              handleValueChange={handleValueChange}
              setCustomValue={setCustomValue}
            />
            {type === TYPES.TABS &&
            <TabsDescription>
              <Markdown activeValue={getActiveValue()}>{description}</Markdown>
            </TabsDescription>
            }
            {type === TYPES.CHECK && (
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="repeatMonthlyCheckbox"
                  onChange={toggleFrequency}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      toggleFrequency();
                    }
                  }}
                  checked={isMonthlyDonation}
                />
                <Label htmlFor="repeatMonthlyCheckbox">
                  {isMobile
                    ? 'Give monthly'
                    : 'Repeat this gift monthly so I can help animals every day.'}
                </Label>
              </CheckboxContainer>
            )}
            <Button href={buttonUrl()} color={BUTTON_COLOR_DONATION[themeBase]} fullWidth>
              <img
                src={
                  TEXT_COLOR[themeBase] === COLORS.WHITE ? LockIconSvgWhite : LockIconSvgDarkGrey
                }
                alt="Lock Icon"
              />
              {isMonthlyDonation ? buttonTextMonthly : buttonTextOneTime}
            </Button>
          </DonationContainer>
        </BoxContainer>
      )}
    </PlatformDetector>
  );
};

DonationBox.propTypes = {
  description: PropTypes.string,
  values: PropTypes.shape({
    monthlyValues: PropTypes.arrayOf(PropTypes.number).isRequired,
    oneTimeValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  theme: PropTypes.string,
  type: PropTypes.oneOf([TYPES.CHECK, TYPES.TABS]),
  preselectedMonthly: PropTypes.oneOf([0, 1, 2]).isRequired,
  preselectedOneTime: PropTypes.oneOf([0, 1, 2]).isRequired,
  buttonTextMonthly: PropTypes.string,
  buttonTextOneTime: PropTypes.string,
  isMonthlyDefault: PropTypes.bool.isRequired,
  handleActiveValue: PropTypes.func,
  handleFrequencyChange: PropTypes.func,
};

DonationBox.defaultProps = {
  monthlyDescription: '',
  theme: 'blueAccessible',
  type: TYPES.CHECK,
  buttonTextMonthly: DEFAULT_MONTHLY_BUTTON_TEXT,
  buttonTextOneTime: DEFAULT_ONE_TIME_BUTTON_TEXT,
  isMonthlyDefault: true,
  handleActiveValue: () => {},
  handleFrequencyChange: () => {},
};

export default DonationBox;
